import React from "react";
import {Field, Form} from "react-final-form";

const WidgetForm = ({children, navigateNext}: { children: React.ReactNode, navigateNext: () => void }) => {
    console.log(children)
    return (
        <Form
            // initialValues={values}
            // validate={this.validate}
            onSubmit={() => {
                navigateNext()
            }}
        >
            {({
                  handleSubmit,
                  submitting,
                  values
              }: { handleSubmit: React.FormEventHandler<HTMLFormElement>, submitting: boolean, values: object }) => (
                <form onSubmit={handleSubmit}>
                    {children}
                    {/*<pre>{JSON.stringify(values)}</pre>*/}
                </form>
            )}
        </Form>
    )
}

const WelcomeWidget = () => {
    return (
        <div className="space-y-4 md:space-y-6">
            <h3 className="font-medium text-2xl">Готовьтесь к&nbsp;триатлону без лишних тренировок</h3>
            <p>Подготовка к&nbsp;стартам только с&nbsp;тренировками, которые улучшают результат и&nbsp;делают
                сильнее.</p>
            <p>Вы&nbsp;получите индивидуальный тренировочный план, который приведет вас к&nbsp;цели, обучающие
                материалы и
                вступите в&nbsp;сообщество триатлетов.</p>
            <p>Подготовка по&nbsp;программе Trisystems состоит из&nbsp;5&nbsp;этапов:</p>
            <div className="format lg:format-lg">
                <ol>
                    <li className="text-sm"><strong>Межсезонная подготовка</strong>
                        <div>Вы&nbsp;будете выполнять аэробные, технические и&nbsp;силовые тренировки</div>
                    </li>
                    <li className="text-sm"><strong>Развитие МПК</strong>
                        <div>Объемы увеличиваются и&nbsp;добавляются интервальные тренировки для развития МПК</div>
                    </li>
                    <li className="text-sm"><strong>Силовая выносливость</strong>
                        <div>Объемы продолжают расти. Добавляется работа на&nbsp;велосипеде на&nbsp;низком каденсе,
                            плавание в
                            лопатках и&nbsp;силовые беговые тренировки
                        </div>
                    </li>
                    <li className="text-sm"><strong>Предсоревновательный</strong>
                        <div>Максимальные тренировочные объемы. Вам предстоят тренировки в&nbsp;темпе гонки
                            и&nbsp;бриковые
                            тренировки
                        </div>
                    </li>
                    <li className="text-sm"><strong>Тейпер (подводка к&nbsp;гонке)</strong>
                        <div>Объемы немного снижаются, чтобы дать вам отдохнуть и&nbsp;вывести на&nbsp;пик формы
                            к&nbsp;главному
                            старту
                        </div>
                    </li>
                </ol>
            </div>
        </div>
    )
}

const EventTitleWidget = () => {
    return (
        <Field name="eventTitle">
            {props => (
                <div className="space-y-4 md:space-y-6">
                    <label htmlFor="eventTitle"
                           className="block mb-2 text-sm font-medium text-gray-900">
                        Введите название события
                    </label>
                    <input {...props.input}
                           id="eventTitle"
                           className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                           placeholder="Например, Ironman Barcelona"/>
                    <button type="submit"
                            className="py-3 px-5 text-sm font-medium text-center text-white rounded-lg bg-primary-700 w-full hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">
                        Продолжить
                    </button>
                </div>
            )}
        </Field>
    )
}

const EventTypeWidget = () => {
    return (
        <Field name="eventType">
            {props => (
                <div className="space-y-4 md:space-y-6">
                    <label htmlFor="eventType"
                           className="block mb-2 text-sm font-medium text-gray-900">
                        Укажите тип события
                    </label>
                    <select  {...props.input}
                             id="eventType"
                             className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                        <option selected>Укажите тип события</option>
                        <option value="IM">IM 226</option>
                        <option value="HIM">Половинка</option>
                        <option value="HIM">Олимпийка</option>
                        <option value="HIM">Спринт</option>
                    </select>
                    <button type="submit"
                            className="py-3 px-5 text-sm font-medium text-center text-white rounded-lg bg-primary-700 w-full hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">
                        Продолжить
                    </button>
                </div>
            )}
        </Field>
    )
}

const EventDateWidget = () => {
    return (
        <Field name="eventDate">
            {props => (
                <div className="space-y-4 md:space-y-6">
                    <label htmlFor="eventTitle"
                           className="block mb-2 text-sm font-medium text-gray-900">
                        Выберите дату старта
                    </label>
                    <div
                        className="bg-gray-100 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5">
                        Нельзя выбрать старт далее 36&nbsp;недель
                    </div>
                    <input {...props.input}
                           id="eventTitle"
                           className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                           placeholder="Например, Ironman Barcelona"/>
                    <button type="submit"
                            className="py-3 px-5 text-sm font-medium text-center text-white rounded-lg bg-primary-700 w-full hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">
                        Продолжить
                    </button>
                </div>
            )}
        </Field>
    )
}

const ExpectedResultWidget = () => {
    return (
        <Field name="eventDate">
            {props => (
                <div className="space-y-4 md:space-y-6">
                    <label htmlFor="eventTitle"
                           className="block mb-2 text-sm font-medium text-gray-900">
                        Выберите дату старта
                    </label>
                    <div
                        className="bg-gray-100 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5">
                        Какой результат вы&nbsp;хотели&nbsp;бы показать? (чч:мм:сс)
                    </div>
                    <input {...props.input}
                           id="eventTitle"
                           className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                           placeholder="Например, Ironman Barcelona"/>
                    <button type="submit"
                            className="py-3 px-5 text-sm font-medium text-center text-white rounded-lg bg-primary-700 w-full hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">
                        Продолжить
                    </button>
                </div>
            )}
        </Field>
    )
}

const BestResultWidget = () => {
    return (
        <Field name="eventDate">
            {props => (
                <div className="space-y-4 md:space-y-6">
                    <label htmlFor="eventTitle"
                           className="block mb-2 text-sm font-medium text-gray-900">
                        Выберите дату старта
                    </label>
                    <div
                        className="bg-gray-100 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5">
                        Какой ваш текущий лучший результат на&nbsp;дистанции, к&nbsp;которой вы&nbsp;готовитесь?
                    </div>
                    <input {...props.input}
                           id="eventTitle"
                           className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                           placeholder="Например, Ironman Barcelona"/>
                    <button type="submit"
                            className="py-3 px-5 text-sm font-medium text-center text-white rounded-lg bg-primary-700 w-full hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">
                        Продолжить
                    </button>
                </div>
            )}
        </Field>
    )
}

const PerformanceWidget = () => {
    return (
        <div className="space-y-4 md:space-y-6">
            <figure>
                <img className="w-full" src="/graph.png" alt="Результаты исследований"/>
                <figcaption className="my-2 text-sm text-gray-500">Результаты исследований&nbsp;&mdash; 78% занимающихся
                    на&nbsp;Trisystems достигают поставленных целей в&nbsp;течение 6&nbsp;месяцев занятий
                </figcaption>
            </figure>
            <div>Trisystems позволит вам достичь цели без выполнения лишних тренировок. Каждая тренировка будет
                вас развивать, вы&nbsp;будете чувствовать, что нагрузка заходит все легче, а&nbsp;скорость,
                относительно пульса, растет.
            </div>
        </div>
    )
}
const SignupWidget = () => {
    return (
        <Field name="email">
            {props => (
                <div className="space-y-4 md:space-y-6">
                    <label htmlFor="email"
                           className="block mb-2 text-sm font-medium text-gray-900">
                        План готов! Введите Email чтобы сохранить его
                    </label>
                    <input {...props.input}
                           id="email"
                           type="email"
                           className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"/>
                    <button type="submit"
                            className="py-3 px-5 text-sm font-medium text-center text-white rounded-lg bg-primary-700 w-full hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">
                        Продолжить
                    </button>
                </div>
            )}
        </Field>
    )
}

const NameWidget = () => {
    return (
        <Field name="name">
            {props => (
                <div className="space-y-4 md:space-y-6">
                    <label htmlFor="name"
                           className="block mb-2 text-sm font-medium text-gray-900">
                        Как вас зовут?
                    </label>
                    <input {...props.input}
                           id="name"
                           className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"/>
                    <button type="submit"
                            className="py-3 px-5 text-sm font-medium text-center text-white rounded-lg bg-primary-700 w-full hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">
                        Продолжить
                    </button>
                </div>
            )}
        </Field>
    )
}

const WeightWidget = () => {
    return (
        <Field name="weight">
            {props => (
                <div className="space-y-4 md:space-y-6">
                    <label htmlFor="weight"
                           className="block mb-2 text-sm font-medium text-gray-900">
                        Укажите ваш вес
                    </label>
                    <input {...props.input}
                           id="weight"
                           className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"/>
                    <button type="submit"
                            className="py-3 px-5 text-sm font-medium text-center text-white rounded-lg bg-primary-700 w-full hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">
                        Продолжить
                    </button>
                </div>
            )}
        </Field>
    )
}

const HeightWidget = () => {
    return (
        <Field name="height">
            {props => (
                <div className="space-y-4 md:space-y-6">
                    <label htmlFor="height"
                           className="block mb-2 text-sm font-medium text-gray-900">
                        Укажите ваш рост
                    </label>
                    <input {...props.input}
                           id="height"
                           className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"/>
                    <button type="submit"
                            className="py-3 px-5 text-sm font-medium text-center text-white rounded-lg bg-primary-700 w-full hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">
                        Продолжить
                    </button>
                </div>
            )}
        </Field>
    )
}

const GenderWidget = () => {
    return (
        <>
            <Field name="weight">
                {props => (
                    <div className="space-y-4 md:space-y-6">
                        <label htmlFor="gender"
                               className="block mb-2 text-sm font-medium text-gray-900">
                            Укажите ваш пол
                        </label>
                        <div
                            className="bg-gray-100 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5">
                            Мужчины и&nbsp;женщины по-разному реагируют на&nbsp;нагрузку
                        </div>
                        <select  {...props.input}
                                 id="gender"
                                 className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                            <option selected>Укажите ваш пол</option>
                            <option value="MALE">Мужской</option>
                            <option value="FEMALE">Женский</option>
                        </select>
                        <button type="submit"
                                className="py-3 px-5 text-sm font-medium text-center text-white rounded-lg bg-primary-700 w-full hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">
                            Продолжить
                        </button>
                    </div>
                )}
            </Field>
        </>
    )
}

const CyclingFtpWidget = () => {
    return (
        <Field name="cyclingFtp">
            {props => (
                <div className="space-y-4 md:space-y-6">
                    <p>
                        FTP&nbsp;&mdash; функциональная пороговая мощность или максимальная средняя мощность, которую
                        вы&nbsp;можете поддерживать в&nbsp;течение 1&nbsp;часа
                    </p>
                    <label htmlFor="cyclingFtp"
                           className="block mb-2 text-sm font-medium text-gray-900">
                        Укажите ваш FTP (велоспорт)
                    </label>
                    <div
                        className="bg-gray-100 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5">
                        Укажите максимальную мощность с&nbsp;которой вы&nbsp;сейчас способны ехать в&nbsp;течение
                        60&nbsp;минут или уровень ПАНО определенный в&nbsp;лабораторном тестировании
                    </div>
                    <input {...props.input}
                           id="cyclingFtp"
                           className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"/>
                    <button type="submit"
                            className="py-3 px-5 text-sm font-medium text-center text-white rounded-lg bg-primary-700 w-full hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">
                        Продолжить
                    </button>
                </div>
            )}
        </Field>
    )
}

const CyclingLthrWidget = () => {
    return (
        <Field name="cyclingLthr">
            {props => (
                <div className="space-y-4 md:space-y-6">
                    <label htmlFor="cyclingLthr"
                           className="block mb-2 text-sm font-medium text-gray-900">
                        Укажите ваш пульс ПАНО (велоспорт)
                    </label>
                    <div
                        className="bg-gray-100 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5">
                        Средний пульс с&nbsp;которым вы&nbsp;сейчас способны проехать 60&nbsp;минут в&nbsp;ровном темпе
                        на&nbsp;максимум или уровень ПАНО определенный в&nbsp;лабораторном тестировании
                    </div>
                    <input {...props.input}
                           id="cyclingLthr"
                           className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"/>
                    <button type="submit"
                            className="py-3 px-5 text-sm font-medium text-center text-white rounded-lg bg-primary-700 w-full hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">
                        Продолжить
                    </button>
                </div>
            )}
        </Field>
    )
}

const RunningPaceWidget = () => {
    return (
        <Field name="runningPace">
            {props => (
                <div className="space-y-4 md:space-y-6">
                    <p>Темп ПАНО&nbsp;&mdash; Критическая скорость плавания, с&nbsp;которой вы&nbsp;проплываете
                        определенный отрезок</p>
                    <label htmlFor="runningPace"
                           className="block mb-2 text-sm font-medium text-gray-900">
                        Укажите ваш темп ПАНО (бег)
                    </label>
                    <div
                        className="bg-gray-100 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5">
                        Средний темп с&nbsp;которым вы&nbsp;сейчас способны бежать 60&nbsp;минут на&nbsp;максимум или
                        уровень ПАНО определенный в&nbsp;лабораторном тестировании
                    </div>
                    <input {...props.input}
                           id="runningPace"
                           className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"/>
                    <button type="submit"
                            className="py-3 px-5 text-sm font-medium text-center text-white rounded-lg bg-primary-700 w-full hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">
                        Продолжить
                    </button>
                </div>
            )}
        </Field>
    )
}

const RunningLthrWidget = () => {
    return (
        <Field name="runningLthr">
            {props => (
                <div className="space-y-4 md:space-y-6">
                    <label htmlFor="runningLthr"
                           className="block mb-2 text-sm font-medium text-gray-900">
                        Укажите ваш пульс ПАНО (бег)
                    </label>
                    <div
                        className="bg-gray-100 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5">
                        Средний пульс с&nbsp;которым вы&nbsp;сейчас способны бежать 60&nbsp;минут в&nbsp;ровном темпе
                        на&nbsp;максимум или уровень ПАНО определенный в&nbsp;лабораторном тестировании
                    </div>
                    <input {...props.input}
                           id="runningLthr"
                           className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"/>
                    <button type="submit"
                            className="py-3 px-5 text-sm font-medium text-center text-white rounded-lg bg-primary-700 w-full hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">
                        Продолжить
                    </button>
                </div>
            )}
        </Field>
    )
}

const SwimmingPaceWidget = () => {
    return (
        <Field name="swimmingPace">
            {props => (
                <div className="space-y-4 md:space-y-6">
                    <label htmlFor="swimmingPace"
                           className="block mb-2 text-sm font-medium text-gray-900">
                        Укажите ваш темп ПАНО (плавание)
                    </label>
                    <div
                        className="bg-gray-100 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5">
                        Cредний темп с&nbsp;которым вы&nbsp;сейчас способны проплыть 2000 метров
                    </div>
                    <input {...props.input}
                           id="swimmingPace"
                           className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"/>
                    <button type="submit"
                            className="py-3 px-5 text-sm font-medium text-center text-white rounded-lg bg-primary-700 w-full hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">
                        Продолжить
                    </button>
                </div>
            )}
        </Field>
    )
}

const LthrInfo1Widget = () => {
    return (
        <div className="space-y-4 md:space-y-6">
            <div className="format lg:format-lg">
                Чтобы тренировки вели к&nbsp;результату, спортсмену необходимо знать свои функциональные параметры.
                Укажите их&nbsp;отдельно по&nbsp;каждому виду спорта: бегу, велосипеду и&nbsp;плаванию. Если какие-то
                не&nbsp;знаете, пропустите: добавите позже&nbsp;&mdash; после первого тестирования
            </div>
        </div>
    )
}

const LthrInfo2Widget = () => {
    return (
        <div className="space-y-4 md:space-y-6">
            <div className="format lg:format-lg">
                Основной параметр на&nbsp;который следует ориентироваться, это уровень ПАНО (другие названия FTP,
                анаэробныйВ порог).
                ПАНО&nbsp;&mdash; это максимальная интенсивность работы при которой скорость образования лактата
                в&nbsp;мышцах ещё равна скорости его утилизации. При превышении уровня ПАНО лактата образуется так
                много, что он&nbsp;не&nbsp;успевает утилизироваться и&nbsp;начинает накапливаться в&nbsp;мышцах.
                Пульс ПАНО можно вычислить из&nbsp;полевых или лабораторных тестов и&nbsp;из&nbsp;соревнований.
                Например, пульс ПАНО для велоспорта это ваш средний пульс на&nbsp;велоэтапе Олимпийки, а&nbsp;для бега
                это ваш средний пульс на&nbsp;полумарафоне, умноженный на&nbsp;1.03.
            </div>
        </div>
    )
}


const PersonalizationInfoWidget = () => {
    return (
        <div className="space-y-4 md:space-y-6">
            <div className="format lg:format-lg">
                Нам нужно настроить программу подготовки под вас лично. Ответ на&nbsp;каждый следующий вопрос повлияет
                на&nbsp;стратегию подготовки и&nbsp;сделает ваш план более эффективным.
            </div>
        </div>
    )
}

const PersonalizationVolumeWidget = () => {
    return (
        <Field name="eventType">
            {props => (
                <div className="space-y-4 md:space-y-6">
                    <label htmlFor="eventType"
                           className="block mb-2 text-sm font-medium text-gray-900">
                        Какой средний недельный тренировочный объем в&nbsp;часах был у&nbsp;вас в&nbsp;последние
                        3&nbsp;месяца?
                    </label>
                    <select  {...props.input}
                             id="eventType"
                             className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                        <option selected>Укажите тип события</option>
                        <option value="4">Менее 4&nbsp;часов</option>
                        <option value="4-6">4-6 часов</option>
                        <option value="6-9">6-9 часов</option>
                        <option value="10-12">10-12 часов</option>
                        <option value="12-15">12-15 часов</option>
                        <option value="15">Более 15&nbsp;часов</option>
                    </select>
                    <div
                        className="bg-gray-100 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5">
                        Если &lt;6&nbsp;&mdash; Чтобы достичь поставленной цели вам предстоит тренироваться немного
                        больше, от&nbsp;5&nbsp;до&nbsp;9&nbsp;часов в&nbsp;неделю в&nbsp;зависимости от&nbsp;периода
                        подготовки. Но&nbsp;тренировочный объем на&nbsp;платформе будет повышаться постепенно,
                        увеличиваясь ближе к&nbsp;главному старту. Таким образом у&nbsp;вас будет время адаптироваться
                        к&nbsp;нагрузке.
                    </div>
                    <div
                        className="bg-gray-100 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5">
                        Если 6-12&nbsp;&mdash; Тренировочные объемы в&nbsp;вашей программе тренировок будут находиться
                        в&nbsp;тех диапазонах, к&nbsp;которым вы&nbsp;уже привыкли. Нагрузка будет разбита на&nbsp;блоки
                        и&nbsp;точно дозирована для
                    </div>
                    <button type="submit"
                            className="py-3 px-5 text-sm font-medium text-center text-white rounded-lg bg-primary-700 w-full hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">
                        Продолжить
                    </button>
                </div>
            )}
        </Field>
    )
}


const InjuryWidget = () => {
    return (
        <Field name="eventType">
            {props => (
                <div className="space-y-4 md:space-y-6">
                    <label htmlFor="eventType"
                           className="block mb-2 text-sm font-medium text-gray-900">
                        Как часто вы&nbsp;сталкивались с&nbsp;травмами за&nbsp;прошедший год?
                    </label>
                    <select  {...props.input}
                             id="eventType"
                             className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                        <option selected>Укажите кол-во травм</option>
                        <option value="1">1&nbsp;раз</option>
                        <option value="2">2&nbsp;раза</option>
                        <option value="3">3&nbsp;и&nbsp;более раз</option>
                        <option value="0">Травмы не&nbsp;беспокоили</option>
                    </select>
                    <div
                        className="bg-gray-100 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5">
                        1&nbsp;раз и&nbsp;более:
                        Травмы вас больше беспокоить не&nbsp;будут. В&nbsp;разработке программ Trisystems участвовали сильнейшие специалисты, тренировки на&nbsp;развитие силы и&nbsp;профилактики травматизма составлены спортивным врачом, КМС по&nbsp;триатлону, Юрием Сдобниковым
                    </div>
                    <button type="submit"
                            className="py-3 px-5 text-sm font-medium text-center text-white rounded-lg bg-primary-700 w-full hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">
                        Продолжить
                    </button>
                </div>
            )}
        </Field>
    )
}

const PaymentWidget = () => {
    return (
        <div className="space-y-4 md:space-y-6">
            <div className="format lg:format-lg">
                <h3>Программа стоит 29&nbsp;000&nbsp;руб.</h3>
                <p>Подготовка на&nbsp;платформе стоит значительно дешевле, чем подготовка с&nbsp;тренером,
                    но&nbsp;эффективность тренировок по&nbsp;нашей системе, как если вы&nbsp;будете тренироваться
                    с&nbsp;лучшим тренером.</p>

                <p>На&nbsp;IronStar Sochi 2023 пять наших спортсменов поднялись на&nbsp;подиум в&nbsp;своих возрастных
                    группах и&nbsp;мы&nbsp;выиграли номинацию &laquo;Самая быстрая команда на&nbsp;дистанции
                    226&raquo;.</p>
            </div>
            <button type="submit"
                    className="py-3 px-5 text-sm font-medium text-center text-white rounded-lg bg-primary-700 w-full hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">
                Купить
            </button>
        </div>
    )
}

const Widget = {
    WidgetForm,
    WelcomeWidget,
    EventTitleWidget,
    EventTypeWidget,
    EventDateWidget,
    PerformanceWidget,
    SignupWidget,
    NameWidget,
    WeightWidget,
    HeightWidget,
    GenderWidget,
    CyclingFtpWidget,
    CyclingLthrWidget,
    RunningPaceWidget,
    RunningLthrWidget,
    SwimmingPaceWidget,
    ExpectedResultWidget,
    BestResultWidget,
    LthrInfo1Widget,
    LthrInfo2Widget,
    PersonalizationInfoWidget,
    PersonalizationVolumeWidget,
    InjuryWidget,
    PaymentWidget
}

export default Widget