import React from "react";
import ReactDOM from "react-dom/client";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import WelcomeWizard from "./WelcomeWizard";


const router = createBrowserRouter([
    {
        path: "/welcome/:step",
        element: <WelcomeWizard/>
    },
]);


const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

root.render(
    <React.StrictMode>
        <RouterProvider router={router}/>
    </React.StrictMode>
);
