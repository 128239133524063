import {useNavigate, useParams} from "react-router-dom";
import Arrow from "./arrow_back_ios_black_24dp.svg";
import Wizard from "./Wizard";
import "./index.css"
import React from "react";

const config: { [step: string]: { Widget: React.FC, hasHeader: boolean, hasContinueBtn: boolean, percent: number, title?: string, next?: string, prev?: string } } = {
    hi: {
        Widget: Wizard.WelcomeWidget,
        hasHeader: false,
        title: "",
        hasContinueBtn: true,
        percent: 1 / 15 * 100,
        next: "event_title",
    },
    event_title: {
        Widget: Wizard.EventTitleWidget,
        hasHeader: true,
        title: "Цель подготовки",
        hasContinueBtn: false,
        percent: 2 / 15 * 100,
        next: "event_type"
    },
    event_type: {
        Widget: Wizard.EventTypeWidget,
        hasHeader: true,
        title: "Цель подготовки",
        hasContinueBtn: false,
        percent: 3 / 15 * 100,
        next: "event_date",
        prev: "event_title"
    },
    event_date: {
        Widget: Wizard.EventDateWidget,
        hasHeader: true,
        title: "Цель подготовки",
        hasContinueBtn: false,
        percent: 4 / 15 * 100,
        next: "performance",
        prev: "event_type"
    },
    performance: {
        Widget: Wizard.PerformanceWidget,
        hasHeader: true,
        title: "Цель подготовки",
        hasContinueBtn: true,
        percent: 5 / 15 * 100,
        next: "signup",
        prev: "event_date"
    },
    signup: {
        Widget: Wizard.SignupWidget,
        hasHeader: true,
        title: "Цель подготовки",
        hasContinueBtn: false,
        percent: 5 / 15 * 100,
        next: "name",
        prev: "performance"
    },
    name: {
        Widget: Wizard.NameWidget,
        hasHeader: true,
        title: "Физиологический профиль",
        hasContinueBtn: false,
        percent: 6 / 15 * 100,
        next: "weight"
    },
    weight: {
        Widget: Wizard.WeightWidget,
        hasHeader: true,
        title: "Физиологический профиль",
        hasContinueBtn: false,
        percent: 7 / 15 * 100,
        next: "height",
        prev: "name"
    },
    height: {
        Widget: Wizard.HeightWidget,
        hasHeader: true,
        title: "Физиологический профиль",
        hasContinueBtn: false,
        percent: 8 / 15 * 100,
        next: "gender",
        prev: "weight"
    },
    gender: {
        Widget: Wizard.GenderWidget,
        hasHeader: true,
        title: "Физиологический профиль",
        hasContinueBtn: false,
        percent: 9 / 15 * 100,
        next: "cycling_ftp",
        prev: "height"
    },
    cycling_ftp: {
        Widget: Wizard.CyclingFtpWidget,
        hasHeader: true,
        title: "Физиологический профиль",
        hasContinueBtn: false,
        percent: 10 / 15 * 100,
        next: "cycling_lthr",
        prev: "gender"
    },
    cycling_lthr: {
        Widget: Wizard.CyclingLthrWidget,
        hasHeader: true,
        title: "Физиологический профиль",
        hasContinueBtn: false,
        percent: 11 / 15 * 100,
        next: "running_pace",
        prev: "cycling_ftp"
    },
    running_pace: {
        Widget: Wizard.RunningPaceWidget,
        hasHeader: true,
        title: "Физиологический профиль",
        hasContinueBtn: false,
        percent: 12 / 15 * 100,
        next: "running_lthr",
        prev: "cycling_lthr"
    },
    running_lthr: {
        Widget: Wizard.RunningLthrWidget,
        hasHeader: true,
        title: "Физиологический профиль",
        hasContinueBtn: false,
        percent: 13 / 15 * 100,
        next: "swimming_pace",
        prev: "running_pace"
    },
    swimming_pace: {
        Widget: Wizard.SwimmingPaceWidget,
        hasHeader: true,
        title: "Физиологический профиль",
        hasContinueBtn: false,
        percent: 14 / 15 * 100,
        next: "payment",
        prev: "running_lthr"
    },
    payment: {
        Widget: Wizard.PaymentWidget,
        hasHeader: false,
        title: "",
        hasContinueBtn: false,
        percent: 100,
    },
}

const WelcomeWizard = () => {
    const navigate = useNavigate();
    const {step} = useParams();
    const {next, prev, hasHeader, hasContinueBtn, percent, title, Widget} = config[step!];

    const navigateNext = () => {
        next && navigate(["/welcome", next].join("/"));
    }

    const navigatePrev = () => {
        prev && navigate(["/welcome", prev].join("/"));
    }

    return (
        <div className="flex flex-col items-center grow w-full">
            <div className="flex flex-col flex-1 grow min-h-screen w-full md:w-128 pt-6 px-6">
                <div className="flex flex-col grow">
                    {hasHeader && (
                        <header className="mb-6 space-y-4">
                            <div className="flex items-center justify-between">
                                {(prev) ? (
                                    <button className="flex-initial" onClick={navigatePrev}>
                                        <img className="h-4 w-4" src={Arrow} alt="Back"/>
                                    </button>
                                ) : (
                                    <div className="flex-inital"/>
                                )}
                                <div className={`flex-initial mx-auto font-medium text-sm uppercase ${prev && "pr-4"}`}>
                                    {title}
                                </div>
                            </div>
                            <div className="w-full bg-green-50 rounded-full h-1">
                                <div className="bg-green-400 h-1 rounded-full" style={{width: percent + "%"}}/>
                            </div>
                        </header>
                    )}
                    <main className="">
                        <Wizard.WidgetForm navigateNext={navigateNext}>
                            <Widget/>
                        </Wizard.WidgetForm>
                    </main>
                </div>
                <div className="fixed left-0 right-0 bottom-0 h-16 z-1 bg-white"/>
                <footer className="sticky bottom-0 z-2 py-6">
                    {hasContinueBtn && next && (
                        <div className="flex justify-center">
                            <button className="py-3 px-5 text-sm font-medium text-center text-white rounded-lg bg-primary-700 w-full hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                                    onClick={navigateNext}>
                                Продолжить
                            </button>
                        </div>
                    )}
                </footer>
            </div>
        </div>
    )
}

export default WelcomeWizard;
